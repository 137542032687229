<template>

  <div>

    <div class="stats-data-select">
      <client-only>
          <n-select v-if="stats_data"
        v-model:value="stat_id"
        filterable
        :options="stats_data"
        value-field="id"
        label-field="name"
        @update:value="getStats()"
      />
    </client-only>
    </div>

    <div style="clear:both">&nbsp;</div>

    <div class="chart_newsletters">

      <vue-highcharts v-if="categories.length"
        ref="statGraph"
        type="chart"
        :options="chartOptions"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        @rendered="onRender"
        @updated="onUpdated"
        />

    </div>

    <div style="clear:both">&nbsp;</div>

  </div>

</template>

<script setup>

import { NSelect } from 'naive-ui'
import VueHighcharts from 'vue3-highcharts';

const {$api} = useNuxtApp()

const props = defineProps({
  data: {
    type: Object    
  }
});

const stat_id = ref('')
const statGraph = ref(null)

const categories = ref([]);
const seriesData = ref([]);

const chartOptions = computed(() => ({
    chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    credits: {
      enabled: false
    },
    accessibility: {
      enabled: false
    },
    xAxis: {
        categories: categories.value,
        crosshair: true,
        labels: {
          formatter() {
            return ''
          }
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Count'
        }
    },
    tooltip: {
        headerFormat: '<table>',
        pointFormat:  '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                      '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: seriesData.value
}));

const loadChartNewsletters = () => {
  $api.getB2BChartNewsletters().then(res => {
    if(res){
      stats_data.value = res;
      stat_id.value = stats_data.value.length>0 ? stats_data.value[0].id : 0;
      getStats();
      return stats_data;
    }
  })
}

const { pending: is_loading,  data: stats_data } = await useLazyAsyncData('stats_data', async () => await loadChartNewsletters())


const getStats = () => {
  if(!stat_id.value){
      return;
  }
  
  $api.getB2BChartNewsletterChart(stat_id.value).then(res => {
    if(res){
      categories.value = res.chart_data.category;
      seriesData.value = res.chart_data.data;
    }
  })
}

const onRender = () => {
  console.log('Chart rendered');
};

const onUpdated = () => {
  console.log('Chart updated');
};

/*
import Highcharts from 'highcharts'

export default { 
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      category: [],
      data_chart: [],
      chartOptions: {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
            categories: [],
            crosshair: true,
            labels: {
              formatter() {
                return ''
              }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Count'
            }
        },
        tooltip: {
            headerFormat: '<table>',
            pointFormat:  '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                          '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: []
      }
    }
  },
  created() {

  },
  watch: {
    data: function (val) {
      if(this.data){
        this.category = this.data.category;
        this.data_chart = this.data.data;
        this.chartOptions.xAxis.categories = this.category;
        this.chartOptions.series = this.data_chart;
      }
    }
  },
  methods: {

  }
}*/
</script>

<style scoped>

/**/
 
</style>

<style>

.stats-data-select{
  width: 260px!important;
}

.chart_newsletters {
  width:100%;
}

.chart_newsletters > div {
  height: 450px;
  width: 100%;
}

</style>